let arrow = document.getElementById('arrow-up');

arrow.addEventListener('click', function(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
});

window.addEventListener('scroll', () => {

    if(window.pageYOffset > 100){
        arrow.classList.add('shown');
    } else {
        arrow.classList.remove('shown');
    }

    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }

});

window.addEventListener('DOMContentLoaded', (event) => {

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 400;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);
});